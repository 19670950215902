import type { GetServerSideProps, NextPage } from "next"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import { NextSeo, OrganizationJsonLd } from "next-seo"
import { NavBar } from "../src/components/universal/NavBar"
import { Footer } from "../src/components/universal/Footer"
import { SectionHeader } from "../src/components/elements/SectionHeader"
import {
  AppStoreButton,
  GooglePlayButton,
} from "../src/components/elements/DownloadButtons"
import { ReviewCard } from "../src/components/cards/ReviewCard"
import { SectionBanner } from "../src/components/SectionBanner"
import { BannerCard } from "../src/components/cards/BannerCard"

// Assets
import ImgPreview1 from "/public/images/landing-cover.jpg"
import ImgPreview2 from "/public/images/landing-cover.jpg"
import ImgPreview3 from "/public/images/landing-cover.jpg"
import ImgPreview4 from "/public/images/landing-cover.jpg"
import ImgPreview5 from "/public/images/landing-cover.jpg"
import ImgAppBanner from "/public/images/app-banner.png"
import ImgAppBannerMob from "/public/images/app-banner-mob.png"
import ImgVideoBanner from "/public/images/video-banner.png"
import ImgVideoBannerMob from "/public/images/video-banner-mob.png"
import ImgLandingCover from "/public/images/landing-cover.jpg"
import ImgLandingCoverCutout from "/public/images/landing-cover-cutout.png"
import ImgFlashRewards from "/public/images/flash-cards/rewards.png"
import ImgFlashSearch from "/public/images/flash-cards/search.png"
import ImgFlashPromo from "/public/images/flash-cards/promo.png"
import screen1 from "/public/images/screenshots/5.jpg"
import screen2 from "/public/images/screenshots/9.jpg"
import screen3 from "/public/images/screenshots/6.jpg"
import screen4 from "/public/images/screenshots/7.jpg"
import SvgApple from "/public/icons/apple.svg"
import SvgChrome from "/public/icons/chrome.svg"
import redeem from "/public/images/features/redeem2.png"
import stacked from "/public/images/features/stacked.png"
import search from "/public/images/features/search3.png"

import { DownloadCTA } from "../src/components/universal/DownloadCTA"
import { ProductItem } from "../src/components/contentItems/ProductItem"
import { productsList } from "../src/products"
import { storesList } from "../src/stores"
import {
  DecodedLink,
  DecodedStoreLink,
  decodeLink,
  decodeStoreLink,
} from "../src/utils/decodeLink"
import { motion } from "framer-motion"
import { IphoneMockup } from "../src/components/elements/iPhoneMockup"
import { StoreItem } from "../src/components/contentItems/StoreItem"
import { FlashCard } from "../src/components/cards/FlashCard"
import React from "react"
import { landingImageList } from "../src/landingImages"
import { FeatureCard } from "../src/components/cards/FeatureCard"

type Props = {
  products: DecodedLink[]
  stores: DecodedStoreLink[]
}

const Home: NextPage<Props> = (props) => {
  // Landing animations
  const landingImageAnim = {
    hidden: { opacity: 1, y: "4%" },
    show: {
      opacity: 1,
      y: "0",
      transition: {
        duration: 0.8,
        // delay: 0.4,
      },
    },
  }

  const landingSloganAnim = {
    hidden: { opacity: 0, y: "-10%" },
    show: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.8,
      },
    },
  }

  // Preview animations
  const previewAnim = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.2,
      },
    },
  }

  const previewItemAnim = {
    hidden: { opacity: 0, y: "200px" },
    show: {
      opacity: 1,
      y: "0",
      transition: {
        ease: "easeOut",
        duration: 0.6,
      },
    },
  }

  const appStoreLink =
    "https://apps.apple.com/us/app/ruby-shop-with-coupons-codes/id1543508948"

  return (
    <div>
      <NextSeo
        title='Save Money & Earn Rewards'
        description='One app to automatically shop and checkout while saving the most by stacking savings.'
        canonical='https://www.shopruby.app'
        openGraph={{
          url: "https://www.shopruby.app/",
          title: "Save Money & Earn Rewards",
          description:
            "One app to automatically shop and checkout while saving the most by stacking savings.",
          images: [
            {
              url:
                "https://www.shopruby.app" +
                require("../public/welcome-screen-shot-800-600.jpg")?.default?.src,
              width: 800,
              height: 600,
              alt: "Og Image Alt",
              type: "image/jpeg",
            },
            // {
            //   url:
            //     "https://www.shopruby.app" +
            //     require("../public/welcome-screen-shot-1440-1000.jpg")?.default?.src,
            //   width: 1440,
            //   height: 1000,
            //   alt: "Og Image Alt Second",
            //   type: "image/jpeg",
            // },
          ],
          site_name: "Save Money & Earn Rewards",
        }}
      />
      <OrganizationJsonLd
        type='Corporation'
        id='https://www.shopruby.app/'
        logo={
          "https://www.shopruby.app" + require("../public/ruby-logo.png")?.default?.src
        }
        legalName='Ruby'
        name='Ruby'
        sameAs={[
          "https://www.facebook.com/shoprubyapp",
          "https://www.linkedin.com/company/shopruby/",
          "https://twitter.com/shoprubyapp",
          "https://www.instagram.com/shoprubyapp/",
          "https://www.pinterest.co.uk/shoprubyapp/_created/",
        ]}
        url='https://www.shopruby.app/'
      />

      {/* <PageMeta title={"Shopping made smarter"} theme={"#F3F4F7"} description={"One app to automatically shop and checkout while saving the most by stacking savings."} /> */}
      <main>
        <NavBar />

        {/* Landing ------------------------------------- */}
        <section>
          <div className='landing wrapper wrapper-full'>
            <div className='bottom wrapper wrapper-full'>
              <motion.div
                className='buttons'
                initial={{ opacity: 0, y: "20px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                <a rel='noreferrer nofollow' href={appStoreLink} target={"_blank"}>
                  <motion.button className='btn-lg-mix bg-primary secondary round'>
                    <SvgApple />
                    App Store
                  </motion.button>
                </a>
                <a
                  rel='noreferrer nofollow'
                  href={"https://chromewebstore.google.com/detail/ruby-auto-coupons-cashbac/dhenlflmddcknkdbohjpcbjmelahphid"}
                  target={"_blank"}
                >
                  <motion.button className='btn-lg-mix bg-secondary round'>
                    <SvgChrome />
                    Add to Chrome
                  </motion.button>
                </a>
              </motion.div>
              <motion.p
                className='h5 weight-500'
                initial={{ opacity: 0, y: "20px" }}
                animate={{ opacity: 0.5, y: "0" }}
                transition={{ duration: 0.8 }}
              >
                Ruby helps you Find the best Deals at your favorite online stores & Save
                money with Coupons, Promo codes, Cash back and Ruby points.
              </motion.p>
            </div>
            {/* <motion.div
              className='image-wrapper cover-cutout'
              variants={landingImageAnim}
              initial='hidden'
              animate='show'
              exit='hidden'
            >
              <Image src={ImgLandingCoverCutout} layout='fill' objectFit='cover' />
            </motion.div> */}
            <div className='top wrapper wrapper-full'>
              <motion.h1
                variants={landingSloganAnim}
                initial='hidden'
                animate='show'
                exit='hidden'
              >
                Get 5% Cashback
                <br />
                Over 50K Stores
              </motion.h1>

              <motion.h3
                variants={landingSloganAnim}
                initial='hidden'
                animate='show'
                exit='hidden'
                className='accent'
              >
                Never pay retail again!
              </motion.h3>
            </div>
            {/* <motion.div
              className='image-wrapper cover'
              variants={landingImageAnim}
              initial='hidden'
              animate='show'
              exit='hidden'
            >
              <Image src={ImgLandingCover} layout='fill' objectFit='cover' />
            </motion.div> */}
            <div className='crossfader'>
              {landingImageList.map((image, idx) => (
                <div key={idx}>
                  <Image src={image} layout='fill' objectFit='cover' />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section>
          <motion.div
            className='preview wrapper'
            variants={previewAnim}
            initial='hidden'
            animate='show'
            exit='hidden'
          >
            <motion.div variants={previewItemAnim}>
              <IphoneMockup image={screen1} alt='first mock up' />
            </motion.div>
            <motion.div variants={previewItemAnim}>
              <IphoneMockup image={screen2} alt='second mock up' />
            </motion.div>
            <motion.div variants={previewItemAnim}>
              <IphoneMockup image={screen3} alt='third mock up' />
            </motion.div>
            <motion.div variants={previewItemAnim}>
              <IphoneMockup image={screen4} alt='third mock up' />
            </motion.div>
          </motion.div>
        </section>

        {/* <section>
          <div className='wrapper'>
            <SectionBanner
              image={ImgAppBanner}
              imageMob={ImgAppBannerMob}
              title={"Save Money & Earn Rewards"}
              caption={`Find Deals at your favorite stores, Apply Promo codes, Get Cash back & Ruby points.`}
              buttonLabel={"App Store"}
              Link={appStoreLink}
            >
              <SvgApple />
            </SectionBanner>
          </div>
        </section> */}

        <section>
          <div className='wrapper cards-wrapper'>
            <FeatureCard
              accent='#FFA6CB'
              title={"Search by coupon, product or store"}
              image={search}
              caption={
                "For ultimate flexibility. Find the best place to get those new sneakers, search for smart watch deals, or explore all the ways to save at one store."
              }
            />
            <SectionHeader
              title={"Stacked Savings"}
              caption={
                "Forget hours of research and reading T&Cs. Ruby combines the best ways to save to get you the biggest discount."
              }
            />
            <FeatureCard
              compact={true}
              halfWidth={true}
              accent='#FFDDC8'
              title={"Every way to save"}
              caption='Cash back, points, limited-time sales… Ruby calculates every single saving available and stacks it all for you. No more post-checkout regrets.'
              image={stacked}
            />
            <FeatureCard
              compact={true}
              halfWidth={true}
              accent='#FFDDC8'
              title={"Redeem your rewards"}
              caption='Earn Ruby points every time you shop, and redeem them at hundreds of your favorite stores. We’ve also partnered with Hoopla Doopla to offer cash back - so that saving money is as easy as spending it.'
              image={redeem}
            />
          </div>
        </section>

        {/* Store Content ------------------------------------- */}
        <section className='content-preview'>
          <div className='wrapper'>
            <SectionHeader
              subtitle='Stores'
              title={"Hundreds of stores, from the biggest names."}
              buttonLabel='Browse on app'
              Link={appStoreLink}
            />
          </div>

          <div className='h-stack loop-h-stack store-loop'>
            <div className='content'>
              {props.stores.map((store, idx) => (
                <StoreItem
                  key={idx}
                  link={`/store/${store.slug}`}
                  image={store.logo}
                  title={store.name}
                  cashback={store.cash_back}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Products Content ------------------------------------- */}
        <section className='content-preview'>
          <div className='wrapper'>
            <SectionHeader
              subtitle='Products'
              title={"Millions of products on sale. All in one place."}
              buttonLabel='Browse on app'
              Link={appStoreLink}
            />
          </div>

          <div className='h-stack loop-h-stack product-loop'>
            <div className='content'>
              {props.products.map((product, idx) => (
                <ProductItem
                  key={idx}
                  link={`/product/${product.slug}`}
                  image={product.product_image}
                  title={product.product_title}
                  store={product.store}
                  salePrice={product.sale_price}
                  price={product.price}
                  discount={product.discount}
                  cashback={product.cash_back}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Flash cards Content ------------------------------------- */}
        <section className='content-preview'>
          <div className='wrapper'>
            <SectionHeader
              title={"Save and redeem cash, every way possible."}
              buttonLabel='Start Saving money'
              Link={appStoreLink}
            />
          </div>

          <div className='h-stack loop-h-stack flash-cards-loop'>
            <div className='content'>
              <FlashCard
                highlight='AI Powered Search & Recommendations '
                title='makes finding deals you love effortless.'
                image={ImgFlashSearch}
                highlightColor='#64FFF6'
                dark
              />
              <FlashCard
                highlight='30.000+ Participating Merchants'
                title=', the largest number of stores ever offered by any app.'
                highlightColor='#56A4FF'
                dark
              />
              <FlashCard
                highlight='Automatic promo code applying '
                title='makes checkout process even faster.'
                highlightColor='#64FF73'
                image={ImgFlashPromo}
                dark
              />
              <FlashCard
                highlight='$115 yearly average savings. '
                title='Make your budget go further with stacked savings.'
                highlightColor='#FF5555'
                image={ImgFlashRewards}
                dark
              />
              <FlashCard
                highlight='17.9% average discount '
                title='on deals available plus exclusive discounts.'
                highlightColor='#FF5555'
                dark
              />
              <FlashCard
                highlight='AI Powered Search & Recommendations '
                title='makes finding deals you love effortless.'
                image={ImgFlashSearch}
                highlightColor='#64FFF6'
                dark
              />
              <FlashCard
                highlight='30.000+ Participating Merchants'
                title=', the largest number of stores ever offered by any app.'
                highlightColor='#56A4FF'
                dark
              />
              <FlashCard
                highlight='Automatic promo code applying '
                title='makes checkout process even faster.'
                highlightColor='#64FF73'
                image={ImgFlashPromo}
                dark
              />
              <FlashCard
                highlight='$115 yearly average savings. '
                title='Make your budget go further with stacked savings.'
                highlightColor='#FF5555'
                image={ImgFlashRewards}
                dark
              />
              <FlashCard
                highlight='17.9% average discount '
                title='on deals available plus exclusive discounts.'
                highlightColor='#FF5555'
                dark
              />
            </div>
          </div>
        </section>

        {/* Cards -------------------------------------- */}
        <section>
          <div className='wrapper'>
            <BannerCard
              image={ImgVideoBanner}
              imageMob={ImgVideoBannerMob}
              title={"Watch Ruby In Action"}
              buttonLabel={"Watch video"}
              Link={"https://youtu.be/n9f5esC0JYo"}
              dark
              mobRev
            />
            {/* <iframe
                src='https://www.youtube.com/embed/n9f5esC0JYo?controls=0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              /> */}
          </div>
        </section>

        <section className='reviews'>
          <div className='wrapper'>
            <SectionHeader
              subtitle='Reviews'
              title={"What do people say?"}
              buttonLabel='View more on App Store'
              Link={appStoreLink}
            />
          </div>
          <div className='h-stack loop-h-stack reviews-loop'>
            <div className='content'>
              <ReviewCard
                title='Shopping Simplified'
                user='C Larson on Appstore'
                caption='I love Ruby, I was able to quickly find and make a home purchase for the best value. I received 4% cashback, free shipping, and a free gift with purchase by using the codes available in Ruby! I love not having to search for coupon codes. The checkout process was seamless with the autofill feature. I’ll definitely shop via Ruby again.'
              />
              <ReviewCard
                title='Savings Galore'
                user='Patrick on Appstore'
                caption='This app greatly streamlines my process of finding deals while shopping online.'
              />
              <ReviewCard
                title='Love this app!'
                user='BryceMitchell on Appstore'
                caption='Having the ability to access all of these coupons and discounts as I shop mobiliy is incredibly simple! Love how easy the app is to use. Will recommend.'
              />
              <ReviewCard
                title='It’s a no brainer!'
                user='picklebrit on Appstore'
                caption='Having all of the coupon codes I need is a great feature and makes shopping seamless for me. Love tHE CASHBACK FEATURE in Ruby!'
              />
              <ReviewCard
                title='Easy to use and helps me save!'
                user='allday89 on Appstore'
                caption='I’m so excited to add this to my list of shopping apps. Ruby is so easy to use and helps me save money when I shop!'
              />
              <ReviewCard
                title='Love the app, very useful'
                user='Da Robsta is bEAsT on Appstore'
                caption='This app is great! Helps me save money on tons of different products!'
              />
              <ReviewCard
                title='Shopping Simplified'
                user='C Larson on Appstore'
                caption='I love Ruby, I was able to quickly find and make a home purchase for the best value. I received 4% cashback, free shipping, and a free gift with purchase by using the codes available in Ruby! I love not having to search for coupon codes. The checkout process was seamless with the autofill feature. I’ll definitely shop via Ruby again.'
              />
              <ReviewCard
                title='Savings Galore'
                user='Patrick on Appstore'
                caption='This app greatly streamlines my process of finding deals while shopping online.'
              />
              <ReviewCard
                title='Love this app!'
                user='BryceMitchell on Appstore'
                caption='Having the ability to access all of these coupons and discounts as I shop mobiliy is incredibly simple! Love how easy the app is to use. Will recommend.'
              />
              <ReviewCard
                title='It’s a no brainer!'
                user='picklebrit on Appstore'
                caption='Having all of the coupon codes I need is a great feature and makes shopping seamless for me. Love tHE CASHBACK FEATURE in Ruby!'
              />
              <ReviewCard
                title='Easy to use and helps me save!'
                user='allday89 on Appstore'
                caption='I’m so excited to add this to my list of shopping apps. Ruby is so easy to use and helps me save money when I shop!'
              />
              <ReviewCard
                title='Love the app, very useful'
                user='Da Robsta is bEAsT on Appstore'
                caption='This app is great! Helps me save money on tons of different products!'
              />
            </div>
          </div>
        </section>

        <section>
          <div className='wrapper'>
            <DownloadCTA />
          </div>
        </section>

        <Footer />
      </main>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const encodedUrls = productsList
  const encodedStoreLinks = storesList

  const stores: any[] = []
  const products: DecodedLink[] = []
  try {
    encodedUrls.forEach((el) => {
      const res = decodeLink(el)
      if (res) {
        products.push(res)
      }
    })

    encodedStoreLinks.forEach((el) => {
      const res = decodeStoreLink(el)
      if (res) {
        stores.push(res)
      }
    })
  } catch (err: any) {
    console.error(err?.message)
  }

  return {
    props: {
      products,
      stores,
    },
  }
}

export default Home
