import React from "react"
import Image from "next/image"
import Link from "next/link"

import SvgDiscount from "/public/icons/discount.svg"
import SvgCashback from "/public/icons/cashback.svg"

type props = {
  image?: any
  title: string
  link: string
  store?: string
  salePrice?: string
  price?: string
  discount?: string
  cashback?: string
}

export const ProductItem: React.FC<props> = (props) => {
  return (
    <Link href={props.link}>
      <a className='product-item'>
        <div className='image-div'>
          <div className='item-cta des'>
            <span className='btn round'>View product</span>
          </div>
          <div className='tags'>
            <div className='tag discount'>
              <SvgDiscount />
              <span>{props.discount}</span>
            </div>
            <div className='tag cashback'>
              <SvgCashback />
              <span>{props.cashback}</span>
            </div>
          </div>
          <img src={props.image} alt='' />
        </div>
        <div className='details'>
          <p className='gray-text footnote'>{props.store}</p>
          <p className='title callout'>{props.title}</p>
          <div className='price'>
            {props.salePrice && <p className='callout'>${props.salePrice}</p>}
            <p className={"callout " + (props?.salePrice && "old-price")}>
              ${props.price}
            </p>
          </div>
        </div>
      </a>
    </Link>
  )
}
