import React from "react"
import Image from "next/image"
import Link from "next/link"

type props = {
  image: any
  imageMob: any
  title: string
  caption?: string
  buttonLabel?: string
  dark?: boolean
  Link: string
  compact?: boolean
  mobRev?: boolean
}

export const BannerCard: React.FC<props> = (props) => {
  return (
    <div className={"banner-card "} style={{ alignItems: props.mobRev ? "flex-end" : "flex-start" }}>
      <div className={"details " + (props.dark ? "secondary" : "primary")}>
        <h2>{props.title}</h2>
        {props.caption && <p>{props.caption}</p>}
        {props.buttonLabel && (
          <Link href={props.Link}>
            <button
              className={
                "btn-lg-mix " +
                (props.dark ? "bg-secondary primary round" : "bg-primary secondary")
              }
            >
              {props.children}
              {props.buttonLabel}
            </button>
          </Link>
        )}
      </div>

      {props.image && (
        <Image
          className='des'
          src={props.image}
          alt='Cover image'
          layout='fill'
          objectFit='cover'
        />
      )}

      {props.imageMob && (
        <Image
          className='mob'
          src={props.imageMob}
          alt='Cover image'
          layout='fill'
          objectFit='cover'
        />
      )}
    </div>
  )
}
