import React from "react"
import Image from "next/image"

type props = {
  image?: any
  title: string
  highlight?: string
  highlightColor?: string
  dark?: boolean
  maxWidth?: number
}

export const FlashCard: React.FC<props> = (props) => {
  return (
    <div
      className={"flash-card " + (props.dark ? "bg-primary" : "bg-secondary")}
      style={{ maxWidth: props.maxWidth, minWidth: props.maxWidth }}
    >
      <div className={"details " + (props.dark ? "secondary" : "primary")}>
        <h4>
          {props.highlight && (
            <span style={{ color: props.highlightColor }}>{props.highlight}</span>
          )}
          {props.title}
        </h4>
      </div>
      {props.image && (
        <div className='image-wrapper'>
          <Image src={props.image} alt='Cover image' layout='fill' objectFit='cover' />
        </div>
      )}
    </div>
  )
}
