import React from "react"
import Image from "next/image"

// Images
import device from "/public/images/iphone-mockup/iphone-device.png"
import shadow from "/public/images/iphone-mockup/iphone-shadow.png"

type props = {
  image?: any
  alt?: string
}

export const IphoneMockup: React.FC<props> = (props) => {
  return (
    <div className='iphone-mockup'>
      <Image src={device} layout='responsive' sizes='100%' priority alt='device frame' />
      <div className='screen-wrapper'>
        <Image src={props.image} layout='fill' sizes='100%' alt={props.alt} />
      </div>
      <div className='shadow-wrapper'>
        <Image src={shadow} layout='responsive' sizes='100%' priority alt='shadow' />
      </div>
    </div>
  )
}
