import React from "react"
import Link from "next/link"

type props = {
  emoji?: string
  subtitle?: string
  title: string
  caption?: string
  buttonLabel?: string
  Link?: string
}

export const SectionHeader: React.FC<props> = (props) => {
  return (
    <div className='section-header'>
      <div className='details'>
        {props?.subtitle && <h4>{props.subtitle}</h4>}
        <h3>{props.title}</h3>
        {props?.caption && <p className='gray-text'>{props.caption}</p>}
      </div>

      {props.buttonLabel && (
        <Link href={props.Link ?? "#"}>
          <button className='des btn-lg-label bg-highlight round'>{props.buttonLabel}</button>
        </Link>
      )}
    </div>
  )
}
