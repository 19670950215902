import React from "react"
import Image from "next/image"

type props = {
  accent?: string
  image: any
  subtitle?: string
  title: string
  caption?: string
  reverse?: boolean
  vertical?: boolean
  compact?: boolean
  halfWidth?: boolean
  direction?: string
}

export const FeatureCard: React.FC<props> = (props) => {
  const Accent = props.accent

  const styles = {
    backgroundAccent: { background: `${Accent}` },
    borderAccent: {
      borderColor: `${Accent}`,
    },
    colorAccent: {
      color: `${Accent}`,
    },
  }

  return (
    <div
      style={styles.backgroundAccent}
      className={
        "feature-card " +
        (props?.compact && " compact ") +
        (props?.halfWidth && " half-width ") +
        (props?.reverse && " card-row-rev ") +
        (props?.vertical && " card-col ") +
        (props?.reverse && props?.vertical && " card-col-rev ")
      }
    >
      {/* Effects */}
      {props.accent && (
        <>
          <div className='border' style={styles.borderAccent} />
          <div className='overlay' />
        </>
      )}

      <div className='details'>
        {props.subtitle && <h4>{props?.subtitle}</h4>}
        <h2>{props.title}</h2>
        <p>{props.caption}</p>
      </div>

      <div className='image-container'>
        <Image
          src={props.image}
          alt='App preview'
          layout='responsive'
          objectFit='cover'
        />
      </div>
    </div>
  )
}
