import React from "react"
import Image from "next/image"

type props = {
  title: string
  caption: string
  user: string
}

export const ReviewCard: React.FC<props> = (props) => {
  return (
    <div className='review-card'>
      <Image src={"/icons/stars.svg"} width={88} height={16} alt="overlay" />
      <h5>{props.title}</h5>
      <p className='callout gray-text'>{props.user}</p>
      <p className='caption'>{props.caption}</p>
    </div>
  )
}
