import React from "react"
import Image from "next/image"
import Link from "next/link"

import SvgDiscount from "/public/icons/discount.svg"
import SvgCashback from "/public/icons/points.svg"

type props = {
  image?: any
  title: string
  link: string
  cashback?: string
}

export const StoreItem: React.FC<props> = (props) => {
  if (props.link != '') {
    return (
      <Link href={props.link}>
        <a className='store-item'>
          <div className='image-div'>
            <img src={props.image} alt='store logo' />
  
            <div className='item-cta des'>
              <span className='btn round'>View store</span>
            </div>
          </div>
          <div className='details'>
            <p className='title callout'>{props.title}</p>
            <div className='tag cashback'>
              <SvgCashback />
              <span>{props.cashback}</span>
            </div>
          </div>
        </a>
      </Link>
    )
  } else {
    return (
      <a className='store-item'>
          <div className='image-div'>
            <img src={props.image} alt='store logo' />
          </div>
          <div className='details'>
            <p className='title callout'>{props.title}</p>
            <div className='tag cashback'>
              <SvgCashback />
              <span>{props.cashback}</span>
            </div>
          </div>
        </a>
    )
  }
  
}
